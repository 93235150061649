<template>
  <base-view title="Cuentas por Pagar" icon="file-text">
    <div>
      <check-authorization :requiresAuthorizations="['reporte concentrado general']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Cuentas por Pagar
          </b-card-header>

          <b-card-body class="p-2">
            <b-button @click="onDownload" size="sm">
              Descargar reporte
            </b-button>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>

export default {
  name: 'ConcentradoGeneralMainView',

  methods: {
    async onDownload () {
      const result = await this.$store.dispatch('concentradoGeneralModule/download', {
        inversionista_id: this.inversionista_id
      })
      await this.$onDownload(result, 'concentrado_general.xlsx')
      this.$notify({ message: 'Descarga del concentrado general.' })
    }
  }
}
</script>

<style scoped>

</style>
